<template>
  <div>
    <b-modal
      size="xl"
      id="modal_select_items"
      title="Agregar producto"
      @ok="closeModalAndSelectItems"
      ok-title="Agregar"
    >
      <div class="row">
        <b-form-group label="Item" class="col-md-6">
          <multiselect
            v-model="formProduct.item"
            track-by="id"
            label="canonical_name"
            placeholder="Selecciona producto"
            :options="formProductProducts"
            :searchable="true"
            :allow-empty="false"
            @search-change="searchProduct"
          >
          </multiselect>
        </b-form-group>
        <b-form-group label="Cantidad" class="col-md-6">
          <b-form-input type="number" v-model="formProduct.quantity">
          </b-form-input>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal id="create__person" title="Crear persona">
      <PersonCreate />
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form>
                <div class="row">
                  <b-form-group label="Destino" class="col-md-6">
                    <multiselect
                      v-model="form.arrival"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona destino"
                      :options="warehouses"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="col-md-6" label="Vehículo">
                    <multiselect
                      v-model="form.vehicle"
                      track-by="id"
                      label="canonical_name"
                      placeholder="Vehículo que transportará el item"
                      :options="vehicles"
                      :searchable="true"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="col-md-6" label="Conductor">
                    <multiselect
                      v-model="form.driver"
                      track-by="id"
                      label="full_name"
                      placeholder="Conductor que transportará el item"
                      :options="drivers"
                      :searchable="true"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group
                    class="col-md-6"
                    label="Vehículo que usará la pieza (opcional)"
                  >
                    <multiselect
                      v-model="form.final_vehicle"
                      track-by="id"
                      label="canonical_name"
                      placeholder="Seleccione un vehículo"
                      :options="vehicles"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group
                    label="Tercero"
                    class="col-md-6"
                    label-size="sm"
                  >
                    <span class="label__create" @click="openModalNewPerson"
                      >[+nuevo]</span
                    >
                    <multiselect
                      v-model="form.third"
                      :multiple="false"
                      track-by="id"
                      label="doc_number"
                      :options="thirds"
                      :searchable="true"
                      :allow-empty="true"
                      @search-change="searchCustomer"
                      placeholder="Ingrese DNI/RUC/CE/PASS"
                    >
                      <template slot="singleLabel" slot-scope="{ option }"
                        >{{ option.doc_number }} -
                        {{ formatCustomer(option) }}
                      </template>
                    </multiselect>
                  </b-form-group>
                </div>
              </b-form>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-button
                  variant="primary"
                  class="mr-2"
                  @click="$bvModal.show('modal_select_items')"
                >
                  Añadir item
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <b-card class="mt-5">
          <b-card-text>
            <b-table
              striped
              hover
              small
              :fields="validated_fields"
              :items="form.details"
              id="validated_products"
            >
              <template #cell(actions)="data">
                <b-button
                  variant="outline-danger"
                  class="mb-2"
                  @click="deleteVerifiedItem(data.item.id)"
                >
                  <b-icon icon="trash" variant="danger"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
        <b-card class="mt-5">
          <b-card-text>
            <div class="row">
              <div class="col-md-12">
                <b-button type="button" variant="primary" @click="save"
                  >Guardar
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";
import PersonCreate from "@/view/pages/person/PersonCreate";

export default {
  name: "LogisticsDispatchCreate",
  components: { PersonCreate },
  data() {
    return {
      formProduct: { item: null, quantity: 1 },
      formProductProducts: [],
      id: null,
      isLoadingFormProductProducts: false,
      validated_fields: [
        { label: "Nombre", key: "name" },
        { label: "Cantidad", key: "quantity" },
        { label: "Acciones", key: "actions" },
      ],
      validated_products: [],
      document_types: [],
      drivers: [],
      action: "Crear",
      form: {
        pos_key: null,
        arrival: null,
        details: [],
        driver: null,
        vehicle: null,
        final_vehicle: null,
        third: null,
      },
      products: [],
      thirds: [],
      isEdit: false,
      vehicles: [],
      warehouses: [],
    };
  },
  methods: {
    save() {
      if (this.validateSave() === false) {
        return;
      }
      const url = `logistics/dispatch/`;

      let form = { ...this.form };
      form.pos_key = localStorage.getItem("pos_key");
      form.driver = Utils.isNotNullOrEmpty(this.form.driver)
        ? this.form.driver.id
        : null;
      form.vehicle = Utils.isNotNullOrEmpty(this.form.vehicle)
        ? this.form.vehicle.id
        : null;
      form.final_vehicle = Utils.isNotNullOrEmpty(this.form.final_vehicle)
        ? this.form.final_vehicle.id
        : null;
      form.third = Utils.isNotNullOrEmpty(this.form.third)
        ? this.form.third.id
        : null;
      form.warehouse = Utils.isNotNullOrEmpty(this.form.warehouse)
        ? this.form.warehouse.id
        : null;
      form.arrival = Utils.isNotNullOrEmpty(this.form.arrival)
        ? this.form.arrival.id
        : null;
      ApiService.post(url, form)
        .then(() => {
          this.$errorToast("Envío guardada");
          this.$router.push({ name: "logistics_dispatch_list" });
        })
        .catch(() => {
          this.$errorToast("Hubo un error al guardar este envío");
        });
    },
    formatCustomer(customer) {
      let customerName = "";
      if (Utils.isNotNullOrEmpty(customer.first_name)) {
        customerName += customer.first_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name)) {
        customerName += customer.last_name + " ";
      }
      if (Utils.isNotNullOrEmpty(customer.last_name_2)) {
        customerName += customer.last_name_2;
      }
      return customerName;
    },
    searchCustomer(query) {
      if (query.length <= 5) {
        return false;
      }
      ApiService.get(
        "core/person",
        `?page=1&page_size=100&doc_number=${query}`
      ).then((res) => {
        this.thirds = res.data.results;
      });
    },
    searchProduct(query) {
      if (query.length <= 3) {
        return false;
      }
      this.isLoadingFormProductProducts = true;
      ApiService.get(
        "core/item",
        `?page=1&page_size=10&canonical_name=${query}`
      ).then((res) => {
        this.formProductProducts = res.data.results;
      });
    },
    deleteVerifiedItem(itemId) {
      this.form.details = this.form.details.filter(
        (x) => x.id !== parseInt(itemId)
      );
    },
    closeModalAndSelectItems(bvModalEvent) {
      // Validate if the form is valid
      if (
        !Utils.isAValidNumber(this.formProduct.quantity) ||
        this.formProduct.item === null
      ) {
        alert("Debe ingresar datos válidos");
        bvModalEvent.preventDefault();
        return false;
      }
      // is valid, so add to selected Items, but check if is it already added
      const findIndex = this.form.details.findIndex(
        (x) => x.id === this.formProduct.item.id
      );
      if (findIndex > -1) {
        this.form.details[findIndex].quantity = this.formProduct.quantity;
        this.form.details[findIndex].item = this.formProduct.item.id;
      } else {
        this.form.details.push({
          id: this.formProduct.item.id,
          name: this.formProduct.item.name,
          quantity: this.formProduct.quantity,
          item: this.formProduct.item.id,
        });
      }
    },
    getDrivers() {
      ApiService.get(`core/user`, "?page=1&page_size=100000").then((res) => {
        this.drivers = res.data.results;
      });
    },
    getWarehouses() {
      ApiService.get(`core/warehouse`, "?page=1&page_size=100000").then(
        (res) => {
          this.warehouses = res.data.results;
        }
      );
    },
    getItems() {
      ApiService.get(
        `store/item`,
        `?provider_product_id=${this.provider_product_id}`
      ).then((res) => {
        console.log(
          "Se buscaron los productos para ",
          this.provider_product_id
        );
        this.$bvModal.show("modal_select_items");
        console.log(res.data);
        this.products = res.data;
      });
    },
    getVehicles() {
      ApiService.get(`core/vehicle`, "?page=1&page_size=100000").then((res) => {
        this.vehicles = res.data.results;
      });
    },
    openModalNewPerson() {
      this.$bvModal.show("create__person");
    },
    validateSave() {
      if (this.form.details.length === 0) {
        this.$errorToast("Debe seleccionar al menos un producto");
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getDrivers();
    this.getWarehouses();
    this.getVehicles();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Crear envío" }]);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-right {
  margin-left: auto;
}

.vue-portal-target {
  width: 100% !important;
}

.label__create {
  font-weight: bold;
  float: right;
  margin-top: -25px;
  color: #3699ff;
  cursor: pointer;
}
</style>
